<template>
    <el-row>
        <!-- 场地名称 -->
        <el-col :span="12">
            <div>场地名称</div>
        </el-col>
        <el-col :span="12">
            <div class="field_check_order_detail_rt">{{order_detail.field_name}}</div>
        </el-col>
    </el-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: mapState({
        order_detail: state => state.order.order_detail,
    }),
}
</script>

<style scoped>
/* 订单详情右侧内容 */
.field_check_order_detail_rt{
    text-align: right;
}
</style>
